<template>
  <div class="homeModuleManage">
    <ds-header title="园区工作人员白名单"></ds-header>
    <a-form layout="inline">
      <a-form-item label="员工名称：">
        <a-input
          v-model.trim="searchForm.userName"
          placeholder="请输入姓名"
          class="inputClass"
        />
      </a-form-item>
      <a-form-item label="员工手机号：">
        <a-input
          v-model.trim="searchForm.userMobile"
          placeholder="请输入手机号"
          class="inputClass"
        />
      </a-form-item>
      <a-form-item label="所在园区：">
        <a-input
          v-model.trim="searchForm.projectName"
          placeholder="请输入园区名称"
          class="inputClass"
        />
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          default-value=""
          style="width: 120px"
          v-model="searchForm.state"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option value="0"> 禁用 </a-select-option>
          <a-select-option value="1"> 启用 </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button
          type="primary"
          @click="search"
          style="margin-top: 3px"
        >
          <a-icon type="search" />查询
        </a-button>
        <a-button
          style="margin-left: 15px"
          @click="resetSearchForm"
        >重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <!-- <a-button
        type="primary"
        @click="addModuleManage()"
      >
        <a-icon type="plus" /> 添加
      </a-button> -->
      <a-button
        type="primary"
        class="downClass"
        @click="downloadTemplate()"
      >
        <a-icon type="download" /> 下载模板
      </a-button>
      <a-upload
        name="file"
        accept=".xlsx, .xls"
        :multiple="true"
        action="/api/dscloud-appservice-management/web/work-user/upload-work-user"
        :headers="upload.headers"
        @change="handleChange"
      >
        <a-button
          type="primary"
          class="uploadClass"
        >
          <a-icon type="upload" /> 导入名单
        </a-button>
      </a-upload>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span
          slot="action"
          slot-scope="text, record"
          class="action"
        >
          <a-button
            v-if="record.state === 1"
            class="btnClass"
            type="link"
            @click="disableUser(record)"
          > 禁用 </a-button>
          <a-button
            v-if="record.state === 0"
            class="btnClass"
            type="link"
            @click="disableUser(record)"
          > 启用 </a-button>
          <a-button
            class="btnClass"
            type="link"
            @click="deleteById(record)"
          > 删除 </a-button>
        </span>
      </a-table>
      <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
    </div>
  </div>
</template>

<script>
import { getStaffWhiteList, disableUserAuthority, deleteUserAuthority } from "@/api/passWhiteManagement";
import ConfirmBoxModule from "@/components/confirmBoxModule";
export default {
  name: "homeModuleManage",
  data() {
    return {
      upload: {
        // 设置上传的请求头部
        headers: { Authorization: JSON.parse(localStorage.getItem("user")).accessToken },
      },
      searchForm: {
        userName: "",
        userMobile: "",
        projectName: "",
        state: "",
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "员工手机号",
            dataIndex: "userMobile",
            key: "userMobile",
          },
          {
            title: "员工姓名",
            dataIndex: "userName",
            key: "userName",
          },
          {
            title: "所在企业",
            dataIndex: "enterpriseName",
            key: "enterpriseName",
          },
          {
            title: "所在园区",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "状态",
            dataIndex: "state",
            key: "state",
            customRender: function (text, record, index) {
              if (text === 0) {
                return "禁用";
              } else {
                return "启用";
              }
            },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  components: {
    ConfirmBoxModule,
  },
  mounted() {
    this.searchTableList(this.table.pagination.current, this.table.pagination.pageSize, this.searchForm);
  },
  methods: {
    // 导入
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功`);
        this.search();
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    // 下载模板
    downloadTemplate() {
      let url =
        "https://sqbj-public.oss-cn-beijing.aliyuncs.com/picture_prod/04dc2de6d8410000_%E6%B5%B7%E6%B7%80%E5%8C%BA%E6%8F%90%E7%BA%A7%E7%AE%A1%E7%90%86%E6%9C%9F%E9%97%B4%E9%87%8D%E7%82%B9%E5%9B%AD%E5%8C%BA%E3%80%81%E4%BC%81%E4%B8%9A%E5%88%B0%E5%B2%97%E5%91%98%E5%B7%A5%E5%8F%B0%E8%B4%A6%E6%A8%A1%E6%9D%BF.xlsx";
      window.open(url);
    },

    // 获取列表
    async searchTableList(page, size, data) {
      const res = await getStaffWhiteList(page, size, data);
      if (res.code === "200") {
        this.table.dataInfo = res.data.list;
        this.table.pagination.total = res.data.count;
      }
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchTableList(this.table.pagination.current, this.table.pagination.pageSize, this.searchForm);
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.userName = "";
      this.searchForm.userMobile = "";
      this.searchForm.projectName = "";
      this.searchForm.state = "";
      this.searchTableList(this.table.pagination.current, this.table.pagination.pageSize, this.searchForm);
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchTableList(this.table.pagination.current, this.table.pagination.pageSize, this.searchForm);
    },
    // 禁用
    disableUser(data) {
      let flag = data.state === 1 ? 0 : 1;
      let that = this;
      this.$confirm({
        title: "确定要" + (flag === 0 ? "禁用" : "启用") + "该员工权限吗？",
        onOk() {
          disableUserAuthority(data.id, flag).then((res) => {
            if (res.code === "200") {
              if (res.data) {
                that.$message.success("操作成功");
                that.searchTableList(that.table.pagination.current, that.table.pagination.pageSize, that.searchForm);
              } else {
                that.$message.error("操作失败");
              }
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    // 删除
    deleteById(data) {
      let that = this;
      this.$confirm({
        title: "确定要删除该员工权限吗？",
        onOk() {
          deleteUserAuthority(data.id).then((res) => {
            if (res.code === "200") {
              if (res.data) {
                that.$message.success("操作成功");
                that.searchTableList(that.table.pagination.current, that.table.pagination.pageSize, that.searchForm);
              } else {
                that.$message.error("操作失败");
              }
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadClass {
  background-color: #909399;
  border-color: #909399;
  margin-left: 10px;
}
.downClass {
  background-color: #ffba00;
  border-color: #ffba00;
  margin-left: 10px;
}
.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.moduleNo {
  color: red;
  margin-top: -5px;
  margin-bottom: -5px;
}

.imgText {
  margin-left: 17%;
  margin-top: -20px;
}

.imgUpload {
  width: 102px;
  height: 102px;
}

.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
